@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;


@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
}

@layer components {
  .my-table-spacing {
    border-spacing: theme("spacing.3");
  }
}

@layer utilities {
  td,
  th {
    @apply my-[8px] px-[20px];
  }

  .body td {
    @apply py-[16px]
  }

thead td {
  @apply py-[10px]
}

/* td div {
  @apply my-[10px]
} */
}

:root {
  --app-height: 100vh;
}

.app-container {
  height: var(--app-height);
  overflow: hidden;
}


.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

  .text-border {
    color: black;
    -webkit-text-stroke: 0.5px white; /* Border color and width */
    display: inline-block;
  }


  .ce-my-message-bubble {
    background-color: #7a39e0 !important;
  }
  
  .ce-chat-title-text {
    color: #7a39e0 !important;
  }
  
  .ce-chat-subtitle-text {
    color: #7a39e0 !important;
    font-weight: 600;
  }
  
  #ce-send-message-button {
    position: relative !important;
    bottom: 2px !important;
    left: 1px !important;
    background-color: #7a39e0 !important;
  }
  
  .ce-my-message-timestamp {
    color: #7a39e0 !important;
  }
  
  .transition-3 {
    transition: all 0.33s ease;
    -webkit-transition: all 0.33s ease; 
    -moz-transition: all 0.33s ease;
  }
  
  .transition-5 {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
  }


  /* call style */

  .call_modal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    background: #0008;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.call_box{
    width: 100%;
    max-width: 400px;
    background: darkblue;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    padding: 50px 0;
    border-radius: 5px;
    box-shadow: 0 0 5px darkblue;
}

.call_box h4, .call_box h6{
    margin-top: 5px;
}

.call_menu{
    min-width: 280px;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.call_menu button{
    font-size: 2rem;
    background: #eee;
    padding: 15px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    outline: none;
}

.call_modal .timer{
    transform: translateY(-15px);
}

.call_modal .show_video{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    pointer-events: none;
}

.call_modal .other_video{
    width: 100%;
    height: 100%;
}

.call_modal .you_video{
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    max-height: 250px;
    border-radius: 5px;
    /* border: 1px solid crimson; */
    z-index: 10;
}

.call_modal .end_call{
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: initial;
    font-size: 2rem;
    background: #eee;
    padding: 15px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    outline: none;
}

.call_modal .time_video{
    position: absolute;
    bottom: 170px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
}


/* message style */
.message{
    
  height: calc(100vh - 100px);
  border: 1px solid #ddd;
  margin-top: 15px;
  border-radius: 3px;
  background: #fbfbfb;
}



.message_header{
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}

.message_header input{
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  background: #c0d8f7;
  padding: 0 5px;
}

.message_header #search{
  display: none;
}

.message_chat_list{
  width: 100%;
  height: calc(100% - 120px);
  overflow-y: auto;
}

.message_chat_list .message_user{
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #f3f3f3;
  color: #555;
  cursor: pointer;
}

.message_chat_list .message_user.active{
  background: #eee;
}

.message_chat_list .message_user a{
  color: #555;
}

.message_chat_list .message_user a:hover{
  text-decoration: none;
}

.message_chat_list .message_user .fa_circle{
  font-size: 8px;
  color: #aaa;
}

.message_chat_list .message_user .fa_circle.active{
  color: forestgreen;
}

/* -----chat input-------- */
.chat_input{
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 10px; */
  border-top: 1px solid #ddd;
}

.chat_input input{
  width: 100%;
  height: 49px;
  border: none;
  outline: none;
}

.chat_input button{
  border: none;
  outline: none;
  background: white;
}

.chat_input .file_upload{
  position: relative;
  overflow: hidden;
  margin: 0 10px;
} 

.chat_input .file_upload #file{
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.show_media{
  width: 100%;
  /* height: 70px; */
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  place-items: center;
  grid-gap: 10px;
  background: #f3f3f3;
  border-radius: 5px;
  padding: 0 5px;
}

.show_media #file_media{
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 70px;
  max-height: 70%;
}

.show_media #file_media img,
.show_media #file_media video{
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.show_media #file_media span{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  background: white;
  border: 1px solid crimson;
  padding: 3px 7px;
  color: crimson;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;

}

/* ----chat container----- */
.chat_container{
  width: 100%;
  height: calc(100% - 110px);
  overflow-y: auto;
  padding: 0 10px;
}

.chat_container .travail{
  transform: translate3d(-90px, -30px, 0px) !important;
}

@media (min-width: 766px){
  .chat_container .travail{
      transform: translate3d(30px, -20px, 0px) !important;
  }
}



.chat_display{
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.chat_row{
  display: grid;
  grid-template-columns: 70%;
  margin-bottom: 10px;
}

.you_message{
  justify-content: end;
  justify-items: end;
}

.other_message{
  justify-content: start;
  justify-items: start;
}

.chat_text{
  padding: 9px 14px;
  margin-bottom: 5px;
}



.you_message .chat_text{
  background-color: #f97316;
  color: white;
  border: 1px solid #f97316;
  border-radius: 14px 14px 0 14px;
}

.other_message .chat_text{
  background-color: #1e1b4b;
  color: white;
  /* border: 1px solid #ddd; */
  border-radius: 14px 14px 14px 0;
}

.chat_title{
  margin-bottom: 3px;
}

.you_content{
  position: relative;
}

.you_content .fa_trash{
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 17px;
  opacity: 0;
}

.you_content:hover .fa_trash{
  opacity: 1;
}

